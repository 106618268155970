<template>
  <b-card>
    <!-- form -->

    <b-row class="mb-2">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Password Settings</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Old Password" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password" :type="passwordFieldTypeOld" placeholder="Old Password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password" label="New Password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew" name="new-password" placeholder="New Password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group label-for="account-retype-new-password" label="Retype New Password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype" name="retype-password" placeholder="New Password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1  float-right" @click="changePassword">
            Save changes
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import router from '@/router';
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    router,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      userData: JSON.parse(localStorage.getItem('userData')),
      optionsLocal: {
        id: '',
        oldPassword: '',
        newPassword: '',
      },
      profileFile: null,
    };
  },

  created() {
    this.optionsLocal.id = this.userData.id;
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
    },
    changePassword() {
      this.$emit('update:formShow', true);
      this.optionsLocal.oldPassword = this.passwordValueOld;
      this.optionsLocal.newPassword = this.newPasswordValue;
      if (this.newPasswordValue == this.RetypePassword) {
        store
          .dispatch('user/updatePassword', this.optionsLocal)
          .then((response) => {
            if (response.status == 200) {
              if (response.data == 'OK') {
                this.$swal({
                  title: 'Success!',
                  text: 'Password has changed!',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                });
                this.$router.push('/');
              } else if (response.data == 'Wrong Password') {
                this.$swal({
                  title: 'Error!',
                  text: 'Incorrect old password',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              }
            }
            this.$emit('update:formShow', false);
          })
          .catch((error) => {
            this.$emit('update:formShow', false);
            this.$swal({
              title: 'Error!',
              text: 'Password could not be changed',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
            console.log(error);
          });
      } else {
        this.$emit('update:formShow', false);
        this.$swal({
          title: 'Error!',
          text: 'The passwords you have entered do not match',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      }
    },
  },

  props: {
    formShow: {
      required: false,
    },
  },
};
</script>
<style lang="scss"></style>
