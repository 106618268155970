<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class=" mt-2">
    <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <account-setting-general :formShow.sync="formShow" />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password :formShow.sync="formShow" />
      </b-tab>

      <!--/ change password tab -->
    </b-tabs>
  </b-overlay>
</template>

<script>
import { BTabs, BTab, BOverlay } from 'bootstrap-vue';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';

import { useRouter } from '@core/utils/utils';
import router from '@/router';
import userStoreModule from '../userStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      formShow: true,
    };
  },
  created() {},
};
</script>
